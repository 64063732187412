<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>诗词</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/listPoem' }">诗词</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info margin-l-sm">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" v-loading="boxLoading">

                <el-form-item label="标题：" label-position="left" prop="title">
                    <el-input v-model="form.title" style="width:180px;"  size="mini"/>
                </el-form-item>
                <el-form-item label="类型：" label-position="left" prop="poem_type_id">
                    <el-select v-model="form.poem_type_id" clearable placeholder="请选择类型" size="mini">
                        <el-option v-for="item in basicsData.typeList" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="朝代：" label-position="left" prop="poem_dynasty_id">
                    <el-select v-model="form.poem_dynasty_id" clearable placeholder="请选择朝代" size="mini">
                        <el-option v-for="item in basicsData.dynastyList" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="作者：" label-position="left" prop="poem_author_id">
                    <el-select v-model="form.poem_author_id" clearable placeholder="请选择作者" size="mini">
                        <el-option v-for="item in basicsData.authorList" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="内容：" label-position="left" prop="content">
                    <editor @onChange="onContent" :content="form.content"></editor>
                </el-form-item>

                <el-form-item label="译文：" label-position="left" prop="translation">
                    <editor @onChange="onTranslation" :content="form.translation"></editor>
                </el-form-item>

                <el-form-item label="译文：" label-position="left" prop="appreciate">
                    <editor @onChange="onAppreciate" :content="form.appreciate"></editor>
                </el-form-item>

                <el-form-item label="注释：" label-position="left" prop="note">
                    <editor @onChange="onNote" :content="form.note"></editor>
                </el-form-item>








                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Editor from "@/components/editor";
export default {
    name: "template-add",
    components: {
        Editor
    },
    data() {
        return {
            boxLoading: false,
            image: '',
            form: {},
            rules: {
                poem_type_id: [
                    { required: true, message: '请选择类型', trigger: 'change' },
                ],
                poem_dynasty_id :[
                    { required: true, message: '请选择朝代', trigger: 'change' },
                ],
                poem_author_id :[
                    { required: true, message: '请选择作者', trigger: 'change' },
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'change' },
                ],
                translation: [
                    { required: true, message: '请输入译文', trigger: 'change' },
                ],
                appreciate: [
                    { required: true, message: '请输入鉴赏', trigger: 'change' },
                ],
                note: [
                    { required: true, message: '请输入注释', trigger: 'change' },
                ],

                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' }
                ],
            },
            loading: false,
            basicsData: {},
        }
    },
    methods: {
        ...mapActions('listPoem', ['addPoem']),
        ...mapActions("common", ["getPoemBaseData"]),
        onContent(content) {
            this.form.content = content
        },
        onTranslation(content) {
            this.form.translation = content
        },
        onAppreciate(content) {
            this.form.appreciate = content
        },
        onNote(content) {
            this.form.note = content
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.add()
                }
            });
        },
        async add() {
            this.loading = true
            const { data, res_info } = await this.addPoem(this.form)
            this.loading = false
            if (res_info != 'ok') return
            this.$message.success('添加成功！')
            this.$router.push('/listPoem')
        },
        async getBasicsData() {
            this.boxLoading = true
            const { data } = await this.getPoemBaseData()
            this.basicsData = data
            // console.log(data);
            this.boxLoading = false
        },
    },
    mounted() {
        this.getBasicsData()
    },
}
</script>

<style scoped>
.margin-l-sm {
    margin-left: 1.25rem;
}
</style>
